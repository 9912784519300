<template>
    <Component
        :is="href ? 'a' : 'button'"
        class="link"
        :class="{disabled}"
        :disabled="disabled"
        :href="href || null"
        :rel="href ? 'noopener' : ''"
    >
        <ArrowIcon class="arrow" v-if="arrow && !right"/>
        <span class="leading-5 border-green-default border-b-4 inline-block" :class="{'border-none': noUnderline, 'underline': cssUnderline}">
            <slot></slot>
        </span>
        <ArrowIcon class="arrow right" v-if="arrow && right"/>
    </Component>
</template>

<script>
import ArrowIcon from '@/../svg/arrow.svg?component';

export default {
    data() {
        return {
        };
    },
    components: {
        ArrowIcon
    },
    mounted() {
    },
    props: {
        disabled: Boolean,
        href: [String, Boolean],
        noUnderline: {
            type: Boolean,
            default: false,
        },
        cssUnderline: {
            type: Boolean,
            default: false,
        },
        arrow: Boolean,
        right: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
    },
    created() {
    },
    computed: {
    },
    watch: {
    }
};
</script>

<style lang="postcss" scoped>

</style>
